import React, {Fragment, useState, useRef, useEffect, useLayoutEffect} from 'react'
import {useParams, useHistory, useLocation}  from 'react-router-dom'
import {useMutation} from 'react-query'
import useStateRef from 'react-usestateref'
import { default as Select } from "react-select"
import NumericInput from 'react-numeric-input'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {AsyncPaginate, reduceGroupedOptions} from 'react-select-async-paginate'
import {useResizeDetector} from 'react-resize-detector'
import {useSolv, useTenant} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, Alert, MainContainer, useAlert, FormGroup, FormGroups} from "./components/FormComps.js"
import ToggleButton from "./components/ToggleButton.js"
import PagePreview from "./PagePreview.js"
import BroadcastDetailsDialog from "./BroadcastDetailsDialog.js"
import BroadcastCloneDialog from "./BroadcastCloneDialog.js"
import BroadcastDeleteDialog from "./BroadcastDeleteDialog.js"
import ImageDialog from "./components/ImageDialog"
import {processImageFile, uploadImage, ImageDropBox} from "./components/ImageUtils"
import {TagBox} from "./components/TagBox.js"
import {BroadcastSummaryWidgets} from "./components/Widget.js"
import {BroadcastStatusTagLabel, getClickActionOpt, getClickActionOpts, getMessageFormatOpt, getWhatsAppPhoneNoFromPageUrl} from "./components/BroadcastUtils.js"
import {parseDate, getDateFormat} from "./components/CustomFieldUtils.js"
import {validateField, validateUrl, clearError, clearAllErrors, InfoBlock, InfoText } from "./components/ValidationUtils.js"
import {isBlankString, shortIdEqual} from './components/StringUtils.js';
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import AccountTabs from "./AccountTabs";
import BroadcastRefundDialog from "./BroadcastRefundDialog";
import {mkHref} from "./components/AccountUtils";
import {getAdSystemName} from "./components/BroadcastComps";
import ExportToFileDialog from "./components/ExportToFileDialog";
import {BroadcastPublishDialog} from "./BroadcastPublishDialog";
import BroadcastStopDialog from "./BroadcastStopDialog";
import {useSearchParams} from "./components/UrlUtils";
import getLogger from "./components/Logging.js"
import "./App.css"

const log = getLogger("Broadcast")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const MAX_LOCATION_COUNT = 10

const DEFAULT_STYLE = {
  border: {
    color: "#FF0000"
  },
  body: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  header: {
    font: "Arial",
    textColor: "#FFFFFF",
    backgroundColor: "#FF0000"
  },
  title: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  content: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  footer: {
    font: "Arial",
    textColor: "#FFFFFF",
    backgroundColor: "#FF0000"
  },
}

export default function Broadcast() {

  const {api, session, env, refreshSignin, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()
  const [publishAlert, setPublishAlert] = useAlert()

  const adSystemOpts = [
    {
      value: "AMOBEE", label: "Amobee"
    },
    {
      value: "GOODWAY", label: "Goodway"
    }
  ]

  const broadcastTimeOpts = [
    {
      value: "ASAP", label: intl.msg("broadcast_target_duration_asap")
    },
    {
      value: "3D", label: intl.msg("broadcast_target_duration_3_days")
    },
    {
      value: "5D", label: intl.msg("broadcast_target_duration_5_days")
    },
    {
      value: "1W", label: intl.msg("broadcast_target_duration_1_weeks")
    },
    {
      value: "2W", label: intl.msg("broadcast_target_duration_2_weeks")
    },
    {
      value: "3W", label: intl.msg("broadcast_target_duration_3_weeks")
    },
    {
      value: "4W", label: intl.msg("broadcast_target_duration_4_weeks")
    }
  ]

  function findOptByValue(opts, value, defaultValue) {
    if (opts && value) {
      const v = opts.find(a => a.value === value)
      return v ? v : defaultValue
    }
    else {
      return defaultValue
    }
  }

  const location = useLocation()
  const params = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const [editMode, setEditMode] = useState((!params.broadcastId) ? "ADD" : (location.pathname.endsWith("_edit") ? "EDIT" : location.pathname.endsWith("_publish") ? "PUBLISH" : null))
  const [broadcastId, setBroadcastId] = useState(params.broadcastId ? params.broadcastId : null)

  const {tenant} = useTenant(params.tenantId)

  const [touched, setTouched] = useState(false)

  const [broadcast, setBroadcast] = useState(null)
  const [publicId, setPublicId] = useState(null)
  const [broadcastName, setBroadcastName] = useState(null)
  const [creativeType, setCreativeType] = useState("IMAGE")
  const [messageFormat, setMessageFormat] = useState(null)
  const [creativeHeader, setCreativeHeader] = useState(null)
  const [creativeTitle, setCreativeTitle] = useState(null)
  const [creativeContent, setCreativeContent] = useState(null)
  const [creativeForm, setCreativeForm] = useState(null)
  const [creativeFormFields, setCreativeFormFields] = useState(null)
  const [creativeFooter, setCreativeFooter] = useState(null)
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [creativeBorderColor, setCreativeBorderColor] = useState("#ff0000")
  const [creativeStyle, setCreativeStyle] = useState({})
  const [creativeTypeLocked, setCreativeTypeLocked] = useState(null)
  const [creativeHeaderLocked, setCreativeHeaderLocked] = useState(null)
  const [creativeTitleLocked, setCreativeTitleLocked] = useState(null)
  const [creativeFooterLocked, setCreativeFooterLocked] = useState(null)
  const [creativeImageUrlLocked, setCreativeImageUrlLocked] = useState(null)
  const [pageType, setPageType] = useState(null)
  const [clickAction, setClickAction] = useState(null)
  const [whatsAppPhoneNo, setWhatsAppPhoneNo] = useState(null)
  const [whatsAppCountry, setWhatsAppCountry] = useState(null)
  const [pageUrl, setPageUrl] = useState(null)
  const [pageHeader, setPageHeader] = useState(null)
  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [pageForm, setPageForm] = useState(null)
  const [pageFormFields, setPageFormFields] = useState(null)
  const [pageImageUrl, setPageImageUrl] = useState(null)
  const [pageStyle, setPageStyle] = useState({})
  const [pageTypeLocked, setPageTypeLocked] = useState(null)
  const [pageHeaderLocked, setPageHeaderLocked] = useState(null)
  const [pageTitleLocked, setPageTitleLocked] = useState(null)
  const [pageUrlLocked, setPageUrlLocked] = useState(null)
  const [pageImageUrlLocked, setPageImageUrlLocked] = useState(null)
  const [extPageLoading, setExtPageLoading] = useState(null)
  const [targetBudget, setTargetBudget] = useState(1)
  const [targetLocations, setTargetLocations] = useState(null)
  const [targetAdViews, setTargetAdViews] = useState(null)
  const [broadcastStatus, setBroadcastStatus] = useState(null)
  const [feedbackEnabled, setFeedbackEnabled] = useState(true)
  const [feedbackForm, setFeedbackForm] = useState(null)
  const [feedbackFormFields, setFeedbackFormFields] = useState(null)
  const [feedbackThankyouText, setFeedbackThankyouText] = useState(null)
  const [adSystem, setAdSystem] = useState(null)
  const [targetStartTime, setTargetStartTime] = useState(findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0]))
  const [targetEndTime, setTargetEndTime] = useState(findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0]))
  const [usdMultiplier, setUsdMultiplier] = useState(null)
  const [broadcastCreatedOn, setBroadcastCreatedOn] = useState(null)
  const [broadcastCreatedBy, setBroadcastCreatedBy] = useState(null)
  const [broadcastModifiedOn, setBroadcastModifiedOn] = useState(null)
  const [broadcastModifiedBy, setBroadcastModifiedBy] = useState(null)
  const [broadcastPublishedOn, setBroadcastPublishedOn] = useState(null)
  const [broadcastPublishedBy, setBroadcastPublishedBy] = useState(null)
  const [broadcastStatsUpdatedOn, setBroadcastStatsUpdatedOn] = useState(null)
  const [broadcastPaymentDetails, setBroadcastPaymentDetails] = useState(null)
  const [popEstimations, setPopEstimations] = useState({totalPop: -1, popReached: -1, popReachedPct: -1, budgetToReach100Pct: -1})
  const [publishDialogState, setPublishDialogState] = useState("START")
  const [broadcastTemplate, setBroadcastTemplate] = useState(null)
  const [creativeImageInfo, setCreativeImageInfo] = useState(null)
  const [pageImageInfo, setPageImageInfo] = useState(null)
  const [paymentTransactionId, setPaymentTransactionId, paymentTransactionIdRef] = useStateRef(null)
  const [tenantCredits, setTenantCredits] = useState(null)
  const [topupCredits, setTopupCredits] = useState(null)
  const [adCampaignProps, setAdCampaignProps] = useState(null)

  const { width: pagePreviewWidth, height: pagePreviewHeight, ref: pagePreviewRef } = useResizeDetector()

  const inpBroadcastNameRef = useRef(null)
  const creativeImagePickerDialogRef = useRef(null)
  const pageImagePickerDialogRef = useRef(null)

  function getSearchParams() {
    const result = {
      broadcastName: searchParams.get("name") || searchParams.get("broadcastName"),
      targetLocations: [...searchParams.getAll("location"), ...searchParams.getAll("targetLocation")],
      targetBudget: searchParams.get("budget") || searchParams.get("targetBudget"),
      targetDuration: searchParams.get("duration") || searchParams.get("targetDuration")
    }
    return result
  }

  function getLocationSearchParams() {
    return [...searchParams.getAll("location"), ...searchParams.getAll("targetLocation")]
  }

  useEffect(() => {

    if (tenant) {
      try {
        setAdSystem("AMOBEE")
        api.getCurrency(tenant.region.currencyCode)
          .then(({data}) => {
            setUsdMultiplier(data.usdMultiplier)
            if ("ADD" === editMode) {
              setBusy(intl.msg("loading"))
              createBroadcastId()
                .then(() => {
                  loadDefaultBroadcastTemplate(tenant.tenantId)
                    .then(() => {
                      const params = getSearchParams()
                      if (params.broadcastName) {
                        setBroadcastName(params.broadcastName)
                      }
                      if (params.targetBudget) {
                        setTargetBudget(params.targetBudget)
                      }
                      if (params.targetDuration) {
                        setTargetEndTime(findOptByValue(broadcastTimeOpts, params.targetDuration, broadcastTimeOpts[0]))
                      }
                      if (params.targetLocations.length > 0) {
                        const payload = {
                          locations: params.targetLocations
                        }
                        api.searchLocations(tenant.regionId, payload)
                          .then(({data}) => {
                            if (Array.isArray(data)) {
                              const ll = data.map((l) => {
                                return ({
                                  value: l.locationId,
                                  label: l.displayName,
                                  pop: l.pop
                                })
                              })
                              setTargetLocations(ll)
                              setFocus()
                            }
                          })
                      }
                      else {
                        setFocus()
                      }
                    })
                    .catch((error) => {
                      log.debug("Fatal error:", error)
                      setFatal(error)
                    })
                })
                .catch((error) => {
                  log.debug("Fatal error:", error)
                  setFatal(error)
                })
                .finally(() => {
                  setBusy(null)
                })
            }
            else {
              setBusy(intl.msg("loading"))
              loadBroadcast(tenant.tenantId, broadcastId)
                .then (({data}) => {
                  if ("PUBLISH" === editMode) {
                    openDialog("dlg_publish")
                  }
                  if (["ADD", "EDIT"].includes(editMode)) {
                    setFocus()
                  }
                })
            }
          })
          .catch((error) => {
            log.debug("Fatal error:", error)
            setFatal(error)
          })
          .finally(() => {

          })
      }
      finally {

      }
    }
  }, [tenant, editMode]);

  function setFocus() {
    const fc = inpBroadcastNameRef.current
    if (fc) {
      fc.disabled = false
      fc.focus()
    }
  }

  useEffect(() => {
    setTopupCredits(targetBudget - tenantCredits)
  }, [tenantCredits, targetBudget])

  function broadcastTimeToOpt(broadcastTime) {
    log.debug("broadcastTimeToOpt: broadcastTime=", broadcastTime)
    if (broadcastTime) {
      switch (broadcastTime.type) {
        case "ASAP":
          return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
        case "DAYS":
          const days = broadcastTime.days ? broadcastTime.days : 0;
          switch (days) {
            case 0:
              return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
            case 7:
              return findOptByValue(broadcastTimeOpts, `1W`, broadcastTimeOpts[0])
            default:
              return findOptByValue(broadcastTimeOpts, `${days}D`, broadcastTimeOpts[0])
          }
          break
        case "WEEKS":
          const weeks = broadcastTime.weeks ? broadcastTime.weeks : 0;
          switch (weeks) {
            case 0:
              return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
            default:
              return findOptByValue(broadcastTimeOpts, `${weeks}W`, broadcastTimeOpts[0])
          }
          break
        case "MONTHS":
          const months = broadcastTime.months ? broadcastTime.months : 0;
          switch (months) {
            case 0:
              return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
            default:
              return findOptByValue(broadcastTimeOpts, `${months}M`, broadcastTimeOpts[0])
          }
          break
        default:
          return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
      }
    }
    else {
      return findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0])
    }
  }

  function optToBroadcastTime(opt) {
    log.debug("optToBrsoadcastTime: opt=", opt)
    if (opt) {
      switch (opt.value) {
        case "ASAP":
          return {"type": "ASAP"}
        case "3D":
          return {"type": "DAYS", "days": 3}
        case "5D":
          return {"type": "DAYS", "days": 5}
        case "7D":
        case "1W":
          return {"type": "WEEKS", "weeks": 1}
        case "2W":
          return {"type": "WEEKS", "weeks": 2}
        case "3W":
          return {"type": "WEEKS", "weeks": 3}
        case "4W":
          return {"type": "WEEKS", "weeks": 4}
        default:
          return {"type": "ASAP"}
      }
    }
    else {
      return {"type": "ASAP"}
    }
  }

  function createBroadcastId() {
    return api.createUuid()
      .then(({data}) => {
        log.debug("createBroadcastId: data=", data)
        setBroadcastId(data.uuid)
      })
  }

  function loadBroadcast(tenantId, broadcastId) {
    setBusy(intl.msg("loading"))
    return api.getBroadcast(tenantId, broadcastId)
      .then(({data: broadcast}) => {
        log.debug(">>>Z: Broadcast loaded: data=", broadcast)
        setBroadcast(broadcast)
        setPublicId(broadcast.publicId)
        setBroadcastName(broadcast.broadcastName)
        setCreativeType(broadcast.creativeType)
        setMessageFormat(getMessageFormatOpt(broadcast.creativeType, tenant, intl))
        setCreativeHeader(broadcast.creativeHeader)
        setCreativeTitle(broadcast.creativeTitle)
        setCreativeContent(broadcast.creativeContent)
        if (broadcast.creativeForm) {
          setCreativeForm(broadcast.creativeForm)
          if (broadcast.creativeForm.fields) {
            setCreativeFormFields(broadcast.creativeForm.fields)
          }
        }
        setCreativeFooter(broadcast.creativeFooter)
        setCreativeStyle(broadcast.creativeStyle)
        setTargetBudget(broadcast.targetBudget)
        const tl = broadcast.targetLocations.map( t => {
          return {label: t.displayName, value: t.locationId, pop: t.pop}
        })
        log.debug("Loaded targetLocations: tl=", tl)
        setTargetLocations(tl)
        setTargetAdViews(broadcast.targetAdViews)
        setPageType(broadcast.pageType)
        setClickAction(getClickActionOpt(broadcast.pageType, tenant, intl))
        setPageHeader(broadcast.pageHeader)
        setPageTitle(broadcast.pageTitle)
        setPageContent(broadcast.pageContent)
        if (broadcast.pageForm) {
          setPageForm(broadcast.pageForm)
          if (broadcast.pageForm.fields) {
            setPageFormFields(broadcast.pageForm.fields)
          }
        }

        if (broadcast.pageUrl && broadcast.pageUrl.trim().length > 0) {
          setPageUrl(broadcast.pageUrl)
        }

        switch (broadcast.pageType) {
          case "EXTERNAL":
            if (validateUrl(broadcast.pageUrl, {relaxed: true, allowedDomains: tenant.allowedDomains, disallowedDomains: tenant.disallowedDomains})) {
              setExtPageLoading("LOADING")
            }
            break
          case "WHATSAPP":
            setWhatsAppPhoneNo(getWhatsAppPhoneNoFromPageUrl(broadcast.pageUrl))
            break
        }

        setPageStyle(broadcast.pageStyle)
        setCreativeImageUrl(broadcast.creativeImageUrl)
        if (broadcast.pageImageUrl && broadcast.pageImageUrl.trim().length > 0) {
          setPageImageUrl(broadcast.pageImageUrl)
        }
        setBroadcastStatus(broadcast.status)
        setBroadcastCreatedOn(broadcast.createdOn)
        setBroadcastCreatedBy(broadcast.createdBy?.userId?.replace(/\-/g, ""))
        setBroadcastModifiedOn(broadcast.modifiedOn)
        setBroadcastModifiedBy(broadcast.modifiedBy?.userId?.replace(/\-/g, ""))
        setBroadcastPublishedOn(broadcast.publishedOn)
        setBroadcastStatsUpdatedOn(broadcast.statsUpdatedOn)
        setBroadcastPublishedBy(broadcast.publishedBy?.userId?.replace(/\-/g, ""))
        setFeedbackEnabled(broadcast.feedbackEnabled)
        if (broadcast.feedbackForm) {
          setFeedbackForm(broadcast.feedbackForm)
          if (broadcast.feedbackForm.fields) {
            setFeedbackFormFields(broadcast.feedbackForm.fields)
          }
        }
        setTargetStartTime(broadcastTimeToOpt(broadcast.targetStartTime))
        setTargetEndTime(broadcastTimeToOpt(broadcast.targetEndTime))
        setBroadcastPaymentDetails(broadcast.paymentDetails)
        if (broadcast.usdMultiplier) {
          setUsdMultiplier(broadcast.usdMultiplier)
        }
        setAdCampaignProps(broadcast.adCampaignProps)
        setAdSystem(findOptByValue(adSystemOpts, broadcast.adSystem, adSystemOpts[0]))
        // log.debug(">>>1", broadcast)
        return (broadcast)
      })
      .catch((error) => {
        if (error.code) {
          log.debug("Fatal error: ", error)
          setFatal(error.code)
        }
        else {
          log.debug("Fatal error: ", error)
          setFatal(error)
        }
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function loadDefaultBroadcastTemplate(tenantId) {
    log.debug("loadDefaultBroadcastTemplate: tenantId=", tenantId)
    const opt = {
      value: "",
      label: "",
      creativeType: "IMAGE",
      creativeHeader: "",
      creativeTitle: "",
      creativeContent: "",
      creativeForm: {},
      creativeFormFields: null,
      creativeFooter: "",
      creativeStyle: {...DEFAULT_STYLE},
      creativeImageUrl: null,
      creativeTypeLocked: true,
      creativeHeaderLocked: true,
      creativeTitleLocked: true,
      creativeImageUrlLocked: true,
      creativeFooterLocked: true,
      pageType: "EXTERNAL",
      pageHeader: "",
      pageTitle: "",
      pageContent: "",
      pageForm: {},
      pageFormFields: null,
      pageUrl: null,
      pageStyle: null,
      pageImageUrl: null,
      pageTypeLocked: false,
      pageHeaderLocked: true,
      pageTitleLocked: true,
      pageUrlLocked: true,
      pageImageUrlLocked: true,
      feedbackEnabled: true,
      feedbackForm: true,
      feedbackFormFields: null,
      feedbackThankyouText: true,
      default: true
    }
    return Promise.resolve(handleBroadcastTemplateChange(opt))

    // return api.findOneBroadcastTemplate(tenantId)
    //   .then(({data}) => {
    //     log.debug("loadDefaultBroadcastTemplate: data=", data)
    //     const opt = {
    //       value: data.broadcastTemplateId,
    //       label: data.broadcastTemplateName,
    //       creativeType: data.creativeType,
    //       creativeHeader: data.creativeHeader,
    //       creativeTitle: data.creativeTitle,
    //       creativeContent: data.creativeContent,
    //       creativeForm: data.creativeForm,
    //       creativeFormFields: data.creativeForm ? data.creativeForm.fields : null,
    //       creativeFooter: data.creativeFooter,
    //       creativeStyle: {...DEFAULT_STYLE, ...(data.creativeStyle ? data.creativeStyle : {})},
    //       creativeImageUrl: data.creativeImageUrl,
    //       creativeTypeLocked: data.creativeTypeLocked,
    //       creativeHeaderLocked: data.creativeHeaderLocked,
    //       creativeTitleLocked: data.creativeTitleLocked,
    //       creativeImageUrlLocked: data.creativeImageUrlLocked,
    //       creativeFooterLocked: data.creativeFooterLocked,
    //       pageType: data.pageType,
    //       pageHeader: data.pageHeader,
    //       pageTitle: data.pageTitle,
    //       pageContent: data.pageContent,
    //       pageForm: data.pageForm,
    //       pageFormFields: data.pageForm ? data.pageForm.fields : null,
    //       pageUrl: data.pageUrl,
    //       pageStyle: data.pageStyle ? data.pageStyle : {border: {color: "#FF0000"}},
    //       pageImageUrl: data.pageImageUrl,
    //       pageTypeLocked: data.pageTypeLocked,
    //       pageHeaderLocked: data.pageHeaderLocked,
    //       pageTitleLocked: data.pageTitleLocked,
    //       pageUrlLocked: data.pageUrlLocked,
    //       pageImageUrlLocked: data.pageImageUrlLocked,
    //       feedbackEnabled: data.feedbackEnabled,
    //       feedbackForm: data.feedbackForm,
    //       feedbackFormFields: data.feedbackForm ? data.feedbackForm.fields : null,
    //       feedbackThankyouText: data.feedbackThankyouText,
    //       default: data.default
    //     }
    //     log.debug("template: opt=", opt)
    //     handleBroadcastTemplateChange(opt)
    //   })
    //   .catch((error) => {
    //     log.debug("loadDefaultBroadcastTemplate: error=", error)
    //     if (error.code !== "NOT_FOUND") {
    //       log.debug("loadDefaultBroadcastTemplate: Load Default BroadcastTemplate failed: error=", error)
    //       throw error
    //     }
    //   })
  }

  function handleBroadcastNameChange(e) {
    setBroadcastName(e.target.value)
    setTouched(true)
  }

  function handleMessageFormatChange(v) {
    setMessageFormat(v)
    setCreativeType(v.value)
    setTouched(true)
  }

  function handleCreativeHeaderChange(e) {
    setCreativeHeader(e.target.value)
    setTouched(true)
  }

  function handleCreativeFooterChange(e) {
    setCreativeFooter(e.target.value)
    setTouched(true)
  }

  async function handleCreativeImageUrlChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: "IMAGE" === creativeType ? 1_000_000 : 1_000_000,
          width: "IMAGE" === creativeType ? 300 : 124,
          height: "IMAGE" === creativeType ? 250 : 164,
        })
      }
      catch (ex) {
        setAlert({type: "error", text: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setCreativeImageInfo(result)
        const el = document.getElementById("inp_dlg_creativeImagePicker")
        el.click()
      }
      else {
        setCreativeImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_creativePhoto")
    }
  }

  function handleCreativePhotoClear() {
    setCreativeImageUrl(null)
    setTouched(true)
  }

  async function handleCreativeTitleChange(v) {
    setCreativeTitle(v.target.value)
    setTouched(true)
  }

  function handleCreativeFormFieldChange(e, index) {
    let g = creativeFormFields[index]
    log.debug("handleCreativeFormFieldChange: g=", g)
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        if ("Invalid Date" === v) {
          v = ""
        }
      }
      catch (e) {
        //
      }
    }
    g.value = v
    if (creativeFormFields.length > 1) {
      setCreativeFormFields([
        ...creativeFormFields.slice(0, index),
        g,
        ...creativeFormFields.slice(index + 1),
      ])
    }
    else {
      setCreativeFormFields([g])
    }
    setTouched(true)
  }

  function handleCreativeBorderColorChange(v) {
    setCreativeBorderColor(v.target.value)
    setTouched(true)
  }

  function handlePageHeaderChange(e) {
    setPageHeader(e.target.value)
    setTouched(true)
  }

  function handlePageTitleChange(v) {
    setPageTitle(v.target.value)
    setTouched(true)
  }

  async function handleSolvPageImageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 2_000_000,
          width: 300,
          height: 400,
        })
      }
      catch (ex) {
        setAlert({type: "error", text: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setPageImageInfo(result)
        const el = document.getElementById("inp_dlg_pageImagePicker")
        el.click()
      }
      else {
        setPageImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_pagePhoto")
    }
  }


  function handlePageImageClear() {
    setPageImageUrl(null)
    setTouched(true)
  }

  function handleClickActionChange(v) {
    setClickAction(v)
    setPageType(v.value)
    setWhatsAppPhoneNo(null)
    setPageUrl(null)
    setTouched(true)
  }

  function handleWhatsAppPhoneNoChange(e) {
    let value = e?.target?.value
    if (value) {
      value = value.replace(/[^0-9]/g, "")
    }
    setWhatsAppPhoneNo(value)
    setPageUrl(`https://wa.me/${encodeURIComponent(value)}`)
  }

  function handleWhatsAppCountryChange(v) {
    setWhatsAppCountry(v)
  }

  function handleExtPageUrlChange(e) {
    const url = e.target.value
    setPageUrl(url)
    setTouched(true)
    if (validateUrl(url, {relaxed: true, allowedDomains: tenant.allowedDomains})) {
      setExtPageLoading("LOADING")
    }
  }

  function handleExtPageUrlLoad() {
    setExtPageLoading("LOADED")
  }

  function handleExtPageUrlError() {
    setExtPageLoading("FAILED")
  }

  function addExtPageUrlHttps() {
    if (pageUrl && pageUrl.trim().length > 0) {
      try {
        if (pageUrl.startsWith("http://")) {
          setPageUrl((prev) => `https://${prev.substring(7)}`)
        }
        else if (!pageUrl.startsWith("https://")) {
          setPageUrl((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleExternalPageUrlBlur() {
    addExtPageUrlHttps()
  }

  function handleExternalPageUrlKeyDown(e) {
    if (e.key === "Enter") {
      addExtPageUrlHttps()
    }
  }

  function handlePageFormFieldChange(e, index) {
    let g = pageFormFields[index]
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        log.debug("handlePageFormFieldChange: DATE: v=", v)
        if ("Invalid Date" === v) {
          v = ""
        }
      }
      catch (e) {
        //
      }
    }
    g.value = v
    if (pageFormFields.length > 1) {
      setPageFormFields([
        ...pageFormFields.slice(0, index),
        g,
        ...pageFormFields.slice(index + 1),
      ])
    }
    else {
      setPageFormFields([g])
    }
    setTouched(true)
  }

  function handleFeedbackFormFieldChange(e, index) {
    let g = feedbackFormFields[index]
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        log.debug("handleFeedbackFormFieldChange: DATE: v=", v)
        if ("Invalid Date" === v) {
          v = ""
        }
      }
      catch (e) {
        //
      }
    }
    g.value = v
    if (feedbackFormFields.length > 1) {
      setFeedbackFormFields([
        ...feedbackFormFields.slice(0, index),
        g,
        ...feedbackFormFields.slice(index + 1),
      ])
    }
    else {
      setFeedbackFormFields([g])
    }
    setTouched(true)
  }

  function loadTargetLocations(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {

    return api.listLocationsByRegion(tenant.regionId, search, "BASIC", "displayName:ASC", additional?.cursor, 50)
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            label: c.ttdType,
            options: [
              {
                value: c.locationId,
                label: c.displayName,
                pop: c.pop
              }
            ],
          }
        })
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadTargetLocations: res=", res)
        return res
      })
      .catch((error) => {
        log.debug("Error loading location", error)
      })
  }

  function loadCountries(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {

    log.debug("loadCountries:", search, loadedOptions, additional)

    return api.listCountries(search, "BASIC", "countryName:ASC", additional?.cursor, 50)
      .then(({data, nextCursor}) => {
        log.debug("loadCountries: data=", data)
        let opt = data.map(c => {
          return {
            options: [
              {
                value: c.countryCode,
                label: c.countryName,
                phoneCode: c.countryPhoneCode
              }
            ],
          }
        })
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadCountries: res=", res)
        return res
      })
      .catch((error) => {
        log.debug("Error loading location", error)
      })
  }


  function handleTargetBudgetChange(value) {
    setTargetBudget(value)
    setTouched(true)
  }

  function handleTargetLocationsChange(value) {
    log.debug("handleTargetLocationsChange: value=", value)
    setTargetLocations(value)
    setTouched(true)
  }

  function handleTargetEndTimeChange(v) {
    setTargetEndTime(v)
    setTouched(true)
  }

  function loadBroadcastTemplates(input) {
    log.debug("loadBroadcastTemplates: input=", input)
    return api.listBroadcastTemplates(tenant.tenantId, input, "FULL", {field: "broadcastTemplateName", direction: "ASC"})
      .then(({data}) => {
        log.debug("loadBroadcastTemplates: data=", data)
        let opt = data.map(c => {
          return {
            value: c.broadcastTemplateId,
            label: c.broadcastTemplateName,
            creativeType: c.creativeType,
            creativeHeader: c.creativeHeader,
            creativeTitle: c.creativeTitle,
            creativeContent: c.creativeContent,
            creativeForm: c.creativeForm,
            creativeFormFields: c.creativeForm ? c.creativeForm.fields : null,
            creativeFooter: c.creativeFooter,
            creativeStyle: c.creativeStyle ? c.creativeStyle : {border: {color: "#FF0000"}},
            creativeImageUrl: c.creativeImageUrl,
            creativeTypeLocked: c.creativeTypeLocked,
            creativeHeaderLocked: c.creativeHeaderLocked,
            creativeTitleLocked: c.creativeTitleLocked,
            creativeFooterLocked: c.creativeFooterLocked,
            creativeImageUrlLocked: c.creativeImageUrlLocked,
            pageType: c.pageType,
            pageHeader: c.pageHeader,
            pageTitle: c.pageTitle,
            pageContent: c.pageContent,
            pageForm: c.pageForm,
            pageFormFields: c.pageForm ? c.pageForm.fields : null,
            pageUrl: c.pageUrl,
            pageStyle: c.pageStyle ? c.pageStyle : {border: {color: "#FF0000"}},
            pageImageUrl: c.pageImageUrl,
            pageTypeLocked: c.pageTypeLocked,
            pageHeaderLocked: c.pageHeaderLocked,
            pageTitleLocked: c.pageTitleLocked,
            pageUrlLocked: c.pageUrlLocked,
            pageImageUrlLocked: c.pageImageUrlLocked,
            feedbackEnabled: c.feedbackEnabled,
            feedbackForm: c.feedbackForm,
            feedbackFormFields: c.feedbackForm ? c.feedbackForm.fields : null,
            feedbackThankyouText: c.feedbackThankyouText,
            default: c.default,
          };
        })
        log.debug("loadBroadcastTemplates: o=", opt)
        return opt
      })
      .catch((error) => {
        log.debug("Error loading broadcast", error)
      })
  }

  function handleBroadcastTemplateChange(value) {
    log.debug("handleBroadcastTemplateChange: value=", value)

    setBroadcastTemplate(value)

    setCreativeHeader(value.creativeHeader)
    setCreativeTitle(value.creativeTitle)
    setCreativeImageUrl(value.creativeImageUrl)
    setCreativeContent(value.creativeContent)
    if (value.creativeForm) {
      setCreativeForm(value.creativeForm)
      if (value.creativeFormFields) {
        setCreativeFormFields(value.creativeFormFields)
      }
    }
    setCreativeFooter(value.creativeFooter)
    setCreativeType(value.creativeType)
    setMessageFormat(getMessageFormatOpt(value.creativeType, tenant, intl))
    setCreativeStyle(value.creativeStyle)
    setCreativeTypeLocked(value.creativeTypeLocked)
    setCreativeHeaderLocked(value.creativeHeaderLocked)
    setCreativeTitleLocked(value.creativeTitleLocked)
    setCreativeFooterLocked(value.creativeFooterLocked)
    setCreativeImageUrlLocked(value.creativeImageUrlLocked)

    setPageHeader(value.pageHeader)
    setPageTitle(value.pageTitle)
    setPageImageUrl(value.pageImageUrl)
    setPageContent(value.pageContent)
    if (value.pageForm) {
      setPageForm(value.pageForm)
      if (value.pageFormFields) {
        setPageFormFields(value.pageFormFields)
      }
    }
    setPageType(value.pageType)
    setClickAction(getClickActionOpt(value.pageType, tenant, intl))
    setPageStyle(value.pageStyle)
    setPageUrl(value.pageUrl)
    setPageTypeLocked(value.pageTypeLocked)
    setPageHeaderLocked(value.pageHeaderLocked)
    setPageTitleLocked(value.pageTitleLocked)
    setPageUrlLocked(value.pageUrlLocked)
    setPageImageUrlLocked(value.pageImageUrlLocked)

    setFeedbackEnabled(value.feedbackEnabled)
    if (value.feedbackForm) {
      setFeedbackForm(value.feedbackForm)
      if (value.feedbackFormFields) {
        setFeedbackFormFields(value.feedbackFormFields)
      }
    }

    setFeedbackThankyouText(value.feedbackThankyouText)

    setTouched(true)
  }

  function handleTopupCreditsChange(e) {
    const v = e.target ? e.target.value : e
    setTopupCredits(v)
    setTouched(true)
  }

  function handleTopupCreditsBlur(e) {
    const v = e.target ? e.target.value : e
    if (v < targetBudget - tenantCredits) {
      setTopupCredits(targetBudget - tenantCredits)
    }
    setTouched(true)
  }


  useEffect(() => {
    calcPopEstimations()
  },[tenant, broadcastId, creativeType, targetBudget, targetLocations, tenant, usdMultiplier])

  function calcPopEstimations() {

    if (tenant) {
      let ttlPop = 0

      let locations = []
      if (targetLocations) {
        locations = targetLocations
      }

      locations.forEach(l => ttlPop = ttlPop + (l.pop > 0 ? l.pop : (l.pop < 0) ? 0 : 1000))

      const adSystemProps = tenant.props?.adSystem ? tenant.props.adSystem["AMOBEE"] : null

      const cpm = adSystemProps ? (adSystemProps["cpms"] ? adSystemProps["cpms"][creativeType] : 1.0) : 1.0

      const impressionsPerUsd = 1000 / cpm

      let ttlImpressions = Math.trunc(targetBudget * usdMultiplier / cpm * 1000)
      let pctReach = ttlImpressions / ttlPop * 100
      if (pctReach === Infinity) {
        pctReach = 0.00
      }
      let budgetToReach100Pct = ttlPop / impressionsPerUsd / usdMultiplier

      const r = {
        ttlPop: ttlPop,
        ttlImpressions: ttlImpressions,
        pctReach: pctReach,
        budgetToReach100Pct: budgetToReach100Pct
      }
      setPopEstimations(r)

      setTargetAdViews(ttlImpressions)

      log.debug(">>>Z: calcPopEstimations: r=", r)
    }
  }

  function validate(e) {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    if (isAdding()) {
      validateField("inp_broadcastTemplate", broadcastTemplate, "required", opt)
    }

    if (!isAdding() || broadcastTemplate) {

      validateField("inp_broadcastName", broadcastName && broadcastName.trim().length > 0, "required", opt)

      if ("SOLV" === creativeType) {
        validateField("inp_creativeHeader", creativeHeader && creativeHeader.trim().length > 0, "required", opt)
        validateField("inp_creativeTitle", creativeTitle && creativeTitle.trim().length > 0, "required", opt)

        if (creativeFormFields) {
          creativeFormFields.forEach(f => {
            if ("REQUIRED" === f.input) {
              validateField(`inp_creativeForm_${f.label.replace(/[\W_]+/g,'')}`, !isBlankString(f.value), "required", opt)
            }
          })
        }
        else {
          opt.valid = false
        }

      }

      validateField("inp_creativePhoto", creativeImageUrl, "required", opt)

      switch (pageType) {
        case "SOLV":
          validateField("inp_pageHeader", pageHeader && pageHeader.trim().length > 0, "required", opt)
          validateField("inp_pageTitle", pageTitle && pageTitle.trim().length > 0, "required", opt)
          validateField("inp_pagePhoto", pageImageUrl, "required", opt)

          if (pageFormFields) {
            pageFormFields.forEach(f => {
              if ("REQUIRED" === f.input) {
                validateField(`inp_pageForm_${f.label.replace(/[\W_]+/g,'')}`, !isBlankString(f.value), "required", opt)
              }
            })
          }
          else {
            opt.valid = false
          }
          break

        case "EXTERNAL":
          if (pageUrl && pageUrl.trim().length > 0) {
            try {
              const url = new URL(pageUrl)
              if (tenant.allowedDomains && tenant.allowedDomains.length > 0) {
                const domain = url.hostname.trim().toUpperCase()
                if (!tenant.allowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                  validateField("inp_pageUrl", false, "whitelist", opt)
                }
              }
              if (tenant.disallowedDomains && tenant.disallowedDomains.length > 0) {
                const domain = url.hostname.trim().toUpperCase()
                if (tenant.disallowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                  validateField("inp_pageUrl", false, "whitelist", opt)
                }
              }
            }
            catch (ex) {
              validateField("inp_pageUrl", false, "valid", opt)
            }
          }
          else {
            validateField("inp_pageUrl", false, "required", opt)
          }
          break

        case "WHATSAPP":
          if (whatsAppPhoneNo && whatsAppPhoneNo.trim().length > 0) {
            validateField("inp_whatsAppPhoneNo", validateUrl(pageUrl), "valid", opt)
          }
          else {
            validateField("inp_whatsAppPhoneNo", false, "valid", opt)
          }
          break

      }

      if (targetLocations) {
        if (targetLocations.length <= 0 || targetLocations.length > MAX_LOCATION_COUNT) {
          validateField("inp_targetLocations", false, "range", opt)
        }
        // if (targetLocations.length <= 0) {
        //   validateField("inp_targetLocations", false, "min-value", opt)
        // }
        // else if (targetLocations.length > MAX_LOCATION_COUNT) {
        //   validateField("inp_targetLocations", false, "max-value", opt)
        // }
      }
      else {
        validateField("inp_targetLocations", false, "required", opt)
      }

      validateField("inp_targetBudget", targetBudget && targetBudget >= 1.00, "min-value", opt)

      // if ("EXTERNAL" === pageType) {
      //   validateField("inp_pageUrl", pageUrl && pageUrl.trim().length > 0, "required", opt)
      //   try {
      //     const url = new URL(pageUrl)
      //     log.debug("pageUrl=", url)
      //     if (tenant.allowedDomains && tenant.allowedDomains.length >  0) {
      //       const domain = url.hostname.trim().toUpperCase()
      //       if (!tenant.allowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
      //         validateField("inp_pageUrl", false, "whitelist", opt)
      //       }
      //     }
      //   }
      //   catch (ex) {
      //     validateField("inp_pageUrl", false, "valid", opt)
      //   }
      // }
      // else {
      //   validateField("inp_pageHeader", pageHeader && pageHeader.trim().length > 0, "required", opt)
      //   validateField("inp_pageTitle", pageTitle && pageTitle.trim().length > 0, "required", opt)
      //   validateField("inp_pagePhoto", pageImageUrl, "required", opt)
      //
      //   if (pageFormFields) {
      //     pageFormFields.forEach(f => {
      //       if ("REQUIRED" === f.input) {
      //         validateField(`inp_pageForm_${f.label.replace(/[\W_]+/g,'')}`, !isBlankString(f.value), "required", opt)
      //       }
      //     })
      //   }
      //   else {
      //     opt.valid = false
      //   }
      // }

    }

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      log.debug("VALIDATE: focus")
      if (e) {
        e.preventDefault()
      }
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function saveBroadcast(payload) {
    if (isAdding()) {
      return api.createBroadcast(tenant.tenantId, payload)
    }
    else {
      return api.updateBroadcast(tenant.tenantId, broadcastId, payload)
    }
  }

  const saveMutation = useMutation(payload => saveBroadcast(payload))

  function changeModeUrl(action, id) {
    const url = window.location.href
    let i = url.lastIndexOf("/_new");
    if (i > 0) {
      const urlWithoutLastPart = url.substring(0, i + 1);
      window.location = `${urlWithoutLastPart}${id}${action ? `/_${action}` : ""}`;
    }
    else {
      i = url.lastIndexOf("/_edit");
      const urlWithoutLastPart = url.substring(0, i);
      window.location = urlWithoutLastPart;
    }
    return url
  }

  function handleSaveClick(action) {
    log.debug(">>>Z: handleSaveClick: action=", action)
    return async function(e) {

      log.debug(">>>Z: handleSaveClick: e=", e)

      log.debug(">>>Z: handleSaveClick: targetEndTime=", targetEndTime, optToBroadcastTime(targetEndTime))

      setBusy(intl.msg("saving"))
      try {

        if (validate(e)) {

          let finalCreativeImageUrl = creativeImageUrl
          let finalPageImageUrl = pageImageUrl

          if (creativeImageUrl && creativeImageUrl.startsWith("data:")) {
            finalCreativeImageUrl = await uploadImage({
              api: api,
              imageData: creativeImageUrl,
              imagePath: `t/${tenant.tenantId}/b/${broadcastId}/m/0`,
              setImageUrl: setCreativeImageUrl,
            })
          }

          if (pageImageUrl && pageImageUrl.startsWith("data:")) {
            finalPageImageUrl = await uploadImage({
              api: api,
              imageData: pageImageUrl,
              imagePath: `t/${tenant.tenantId}/b/${broadcastId}/p/0`,
              setImageUrl: setPageImageUrl,
            })
          }

          let _pageUrl = null
          switch (pageType) {
            case "SOLV":
              _pageUrl = null
              break
            case "EXTERNAL":
            case "WHATSAPP":
            case "SIGNAL":
              _pageUrl = pageUrl
              break
            case "HOME":
              _pageUrl = tenant.homePage || pageUrl
          }

          let payload = {
            broadcastId: broadcastId,
            tenantId: tenant.tenantId,
            broadcastName: broadcastName,
            creativeType: creativeType ? creativeType : "SOLV",
            creativeHeader: creativeHeader ? creativeHeader : "",
            creativeTitle: creativeTitle,
            creativeContent: creativeContent,
            creativeFooter: creativeFooter ? creativeFooter : "",
            creativeForm: mkFormAndFields(creativeForm, creativeFormFields),
            creativeStyle: creativeStyle,
            creativeImageUrl: finalCreativeImageUrl,
            pageType: pageType ? pageType : "SOLV",
            pageHeader: pageHeader ? pageHeader : "",
            pageTitle: pageTitle,
            pageContent: pageContent,
            pageUrl: ("SOLV" === pageType ? null : pageUrl),
            pageForm: mkFormAndFields(pageForm, pageFormFields),
            pageStyle: pageStyle,
            pageImageUrl: finalPageImageUrl ? finalPageImageUrl : "",
            feedbackEnabled: feedbackEnabled,
            feedbackForm: mkFormAndFields(feedbackForm, feedbackFormFields),
            feedbackThankyouText: feedbackThankyouText,
            targetBudget: targetBudget,
            targetLocations: targetLocations.map(t => t.value),
            targetAdViews: targetAdViews,
            adSystem: "AMOBEE",
            targetStartTime: optToBroadcastTime(targetStartTime),
            targetEndTime: optToBroadcastTime(targetEndTime),
            status: broadcastStatus,
          }

          const {data} = await saveMutation.mutateAsync(payload)

          log.debug("Save result=", data)

          resetEditMode()

          changeModeUrl(action, data.broadcastId)

        }
        else {
          setAlert({error: intl.msg("error_invalid_form")})
        }
      }
      catch (ex) {
        // setTouched(false)
        log.debug("Save broadcast failed: ex=", JSON.stringify(ex))
        handleError(ex)
      }
      finally {
        setBusy(null)
      }
    }
  }

  function handleEditClick(e) {
    if (canEdit()) {
      window.location = mkHref({suffix: `/broadcasts/${broadcastId}/_edit`, tenant: tenant, user: session?.user})
      // window.location = window.location + "/_edit"
      // setEditMode("EDIT")
      // const fc = document.querySelector(".form-control")
      // if (fc) {
      //   fc.disabled = false
      //   fc.focus()
      // }
    }
  }

  function handleError(ex) {
    if (ex.code) {
      switch (ex.code) {
        case "DUPLICATE_KEY":
          log.debug("DUPLICATE_ERROR")
          setAlert({error: intl.msg("error_invalid_form"), field: "inp_broadcastName", constraint: "unique"})
          // showError("inp_broadcastName", "unique")
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
      // setError(intl.fm("error_failed"))
    }
  }

  function handleReloadClick()  {
    if (broadcastId) {
      window.location = `/broadcasts/${broadcastId}`
    }
  }

  function mkFormAndFields(form, formFields) {
    let f
    if (form && formFields) {
      f = {...form}
      f.fields = formFields
    }
    return f
  }

  const publishMutation = useMutation(payload => api.publishBroadcast(tenant.tenantId, payload))

  async function doPublishWithCredits() {
    const payload = {
      provider: "SOLV_CREDITS",
      broadcastId: broadcastId,
      details: {}
    }

    log.debug("doPublishWithCredits: payload=", payload)

    const res = await publishMutation.mutateAsync(payload)
    log.debug("doPublishWithCredits: publishhMutation: res=", res)

    return res

  }

  async function handlePublishWithCredits() {
    log.debug("handlePublishWithCredits: invoked", tenant)

    // if (!user.isSystemUser() && "TRIAL" === tenant.tenantPlan) {
    //   const e = document.getElementById("inp_upgradePlanDialog")
    //   if (e) {
    //     e.click()
    //     return
    //   }
    // }

    setBusy(intl.msg("publishing"))

    try {

      const payload = {
        provider: "SOLV_CREDITS",
        broadcastId: broadcastId,
        details: {}
      }

      log.debug("handlePublishWithCredits: payload=", payload)

      const res = await publishMutation.mutateAsync(payload)

      log.debug("handlePublishWithCredits: publishhMutation: res=", res)

      setPublicId(res.publicId)
      setAlert(null)
      setPublishAlert(null)
      setPublishDialogState("THANK_YOU")
      setBroadcastStatus("PUBLISHED")
      setTouched(false)
    }
    catch (ex) {
      log.debug("publishedWithCredits failed", JSON.stringify(ex))
      switch (ex.code) {
        case "INSUFFICIENT_FUNDS":
          // setPublishAlert({error: intl.fm("broadcast_publish_error_insufficient_credits")})
          setPublishDialogState("PAY")
          const tc = parseFloat(ex.details.tenantCredits || 0.0)
          setTenantCredits(tc)
          setTopupCredits(targetBudget - tc)
          break
        default:
          closePubishDialog()
          setAlert({error: intl.msg("error_failed")})
        // setError(intl.fm("error_failed"))
      }
    }
    finally {
      log.debug("Done publishing...")
      setBusy(null)
    }
  }

  // const upgradePlanMutation = useMutation(payload => api.upgradePlan(payload))
  //
  // async function handleUpgradePlanClick() {
  //   setBusy("Upgrading...")
  //   try {
  //     let {data} = await upgradePlanMutation.mutateAsync({})
  //     const ses = await refreshSignin()
  //     if (ses && data.tenant) {
  //       log.debug("handleUpgradePlanClick: ses=", ses)
  //       // setTenant(ses)
  //     }
  //     setPublishDialogState("START")
  //     setPublishAlert(null)
  //   }
  //   catch (ex) {
  //     setPublishAlert({error: intl.msg("error_upgrade_plan_failed")})
  //   }
  //   finally {
  //     setBusy(null)
  //   }
  // }

  function closePubishDialog() {
    const el = document.getElementById("btn_dlg_publish_close")
    if (el) {
      el.click()
    }
  }

  function getDemoUrl() {
    return `${env.DEMO_ENDPOINT}/${tenant.demoId}`
  }

  const markDemoMutation = useMutation(
    payload => api.markBroadcastForDemo(tenant.tenantId, payload.broadcastId),
    {
      onSuccess: ({data}) => {
        setAlert(null)
        setTouched(false)
      },
      onError: (error) => {
        setAlert({error: intl.msg("error_failed")})
      }
    }
  )

  async function handlePaypalCreateOrder(orderData, actions) {

    log.debug("handlePaypalCreateOrder: orderData=", orderData)

    setBusy(intl.msg("working"))

    try {
      const payload = {
        paymentTransactionType: "TOP_UP",
        paymentMethodId: "PAYPAL_CHECKOUT",
        amount: topupCredits,
        usdMultiplier: usdMultiplier,
        details: orderData
      }
      const {data}  = await api.createPaymentTransaction(payload)
      if (data) {
        log.debug("handlePaypalCreateOrder: data=", data)
        setPaymentTransactionId(data.paymentTransactionId)
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: `${tenant.region.currencyCode ? tenant.region.currencyCode : "USD"}`,
                value: topupCredits
              }
            }
          ],
          application_context: {
            brand_name: "SOLV: Publish Broadcast",
            shipping_preference: "NO_SHIPPING"
          }
        })
      }
      else {
        log.debug("handlePaypalCreateOrder: error=", data.error)
        setPublishAlert({error: intl.msg("error_paypal_failed")})
        return false
      }
    }
    finally {
      setBusy(null)
    }
  }

  async function handlePaypalOnApprove(orderData, actions) {

    log.debug("handlePaypalOnApprove: orderData=", orderData)

    setBusy(intl.msg("publishing"))

    try {

      let details = await actions.order.capture() || {}

      details.broadcast = {
        broadcastId: broadcastId,
        broadcastName: broadcastName
      }

      const payload = {
        paymentTransactionId: paymentTransactionIdRef.current,
        purchaseType: "CREDITS",
        description: `Publish (broadcast "${broadcastName}")`,
        details: details,
      }

      log.debug(`handlePaypalOnApprovex: payload=`, payload)

      const res = await api.purchaseCredits(tenant.tenantId, payload)

      log.debug("handlePaypalOnApprovex: purchaseCredits: res=", res)

    }
    catch (ex) {
      setPublishAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }

    handlePublishWithCredits()

  }

  async function handleDemoClick() {

    setBusy(intl.msg("working"))
    try {

      let payload = {
        broadcastId: broadcastId
      }

      log.debug("handleDemoClick: payload=", payload)

      await markDemoMutation.mutateAsync(payload)

      window.open(getDemoUrl(), "_blank")

      document.getElementById("inp_broadcastName").focus()
    }
    finally {
      setBusy(null)
    }
  }

  async function handleRepublishClick() {

    setBusy(intl.msg("working"))
    try {

      const {data} = api.republishBroadcast(tenant.tenantId, broadcastId)
      window.location.reload()

    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  async function handleExport() {
    return await api.exportBroadcast(tenant.tenantId,{broadcastId: broadcastId})
  }

  function canEdit(locked) {
    if (tenant && tenant.accessingAs(["BASIC/MEMBER/*", "SUPERADMMIN/MANAGER/*"])) {
      if (true === locked) {
        return false
      }
      else {
        if ("ADD" === editMode) {
          return true
        }
        else if (broadcastStatus === "DRAFT") {
          if (shortIdEqual(broadcastCreatedBy, session.user.userId) || session.user.isSupervisorOrAbove()) {
            return true
          }
        }
      }
    }
    return false
  }

  function canStop() {
    return (["STARTED", "IMPORTED"].includes(broadcastStatus) && (isBroadcastCreator() || (tenant.accessingAs("ADMIN/MEMBER/CLIENT") || tenant.accessingAs("SUPERADMIN/MANAGER/*"))))
  }

  function isEditing(locked) {
    return canEdit(locked) && ["ADD", "EDIT"].includes(editMode)
  }

  function isAdding() {
    return canEdit() && "ADD" === editMode
  }

  function isPublished() {
    return ("DRAFT" !== broadcastStatus)
  }

  function isDelivering() {
    return ("STARTED" === broadcastStatus && broadcastStatsUpdatedOn)
  }

  function resetEditMode() {
    if (canEdit()) {
      setEditMode(null)
      setTouched(false)
    }
    setAlert(null)
  }

  function handleCancelClick() {
    window.location = mkHref({suffix: `/broadcasts/`, tenant: tenant, user: session?.user})
    // history.push("/broadcasts")
  }

  function isBroadcastCreator() {
    return session && session.user && session.user.userId.replace(/\-/g, "") === broadcastCreatedBy
  }

  function isBroadcastModifier() {
    return session && session.user && session.user.userId.replace(/\-/g, "") === broadcastModifiedBy
  }

  function canPublish() {
    if ("DRAFT" === broadcastStatus) {
      if (tenant && (tenant.isClient() || tenant.isSystem()) && !["TRIAL"].includes(tenant.tenantPlanId)) {
        if (
          (tenant.accessingAs("BASIC/MEMBER/*") && isBroadcastCreator()) ||
          (tenant.accessingAs("SUPERVISOR/MEMBER/*")) ||
          (tenant.accessingAs("ADMIN/MANAGER/*"))
        ) {
          return true
        }
      }
    }
    return false
  }

  function canSyncStats() {
    return tenant.accessingAs("*/ADMIN/SYSTEM")
  }

  function MoreButton(props) {
    const cmps = []

    if (tenant) {

      if (!touched) {

        // if (tenant.accessingAs("ADMIN/*/SYSTEM") && broadcastStatus === "FAILED_IMPORT") {
        //   cmps.push(
        //     <a className="dropdown-item" href="#" onClick={handleRepublishClick}>
        //       <i className="fas fa-paper-plane mr-2"></i>
        //       {intl.msg("republish")}
        //     </a>
        //   )
        // }

        if (tenant.accessingAs("*/MEMBER/*")) {
          if (cmps.length > 0) {
            cmps.push(
              <div className="dropdown-divider"></div>
            )
          }
          cmps.push(
            <a className="dropdown-item" href="#" onClick={handleDemoClick}>
              <i className="fas fa-tv mr-2"></i>
              {intl.msg("broadcast_present")}
            </a>
          )
        }

        if (isBroadcastCreator() || tenant.accessingAt("SUPERVISOR")) {

          cmps.push(
            <a className="dropdown-item" href="#" onClick={openDialogCurried("dlg_broadcast_clone")}>
              <i className="fas fa-clone mr-2"></i>
              {intl.msg("clone")}
            </a>
          )

          cmps.push(
            <a className="dropdown-item" href="#" onClick={openDialogCurried("dlg_broadcast_export")}>
              <i className="fas fa-download mr-2"></i>
              {intl.msg("export")}
            </a>
          )

        }

        if (canStop()) {
          if (cmps.length > 0) {
            cmps.push(
              <div className="dropdown-divider"></div>
            )
          }
          cmps.push(
            <a key="key_more_stop" className="dropdown-item" href="#" onClick={openDialogCurried("dlg_broadcast_stop")}>
              <i className="fas fa-exclamation-triangle mr-2"></i>
              {intl.msg("stop")}
            </a>
          )
        }

        if ((broadcastStatus === "DRAFT") && (isBroadcastCreator() || tenant.accessingAs(["SUPERVISOR/MEMBER/*", "ADMIN/MANAGER/*"]))) {
          if (cmps.length > 0) {
            cmps.push(
              <div className="dropdown-divider"></div>
            )
          }
          cmps.push(
            <a key="key_more_delete" className="dropdown-item" href="#" onClick={openDialogCurried("dlg_broadcast_delete")}>
              <i className="fas fa-trash-alt mr-2"></i>
              {intl.msg("delete")}
            </a>
          )
        }

      }

      if (cmps.length > 0) {
        cmps.push(
          <div className="dropdown-divider"></div>
        )
      }

      cmps.push(
        <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_broadcast_details")}>
          <i className="fas fa-info-circle mr-2"></i>
          {intl.msg("details")}
        </a>
      )

    }
    return (
      cmps.length > 0 &&
        <div key={"key_more_button"}>
          <button className="btn btn-secondary" type="button" id="dropdownMenuButton" title={intl.msg("more_menu")}
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-ellipsis-v"></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {cmps}
          </div>
        </div>
    )
  }

  function mkDomainListText(allowedDomains) {
    if (allowedDomains.length > 1) {
      return (
        <>
          one of {
          allowedDomains.map((s, i) => {
            return (
              <><code>{s}</code>{i < (allowedDomains.length - 1) ? ", " : ""}</>
            )
          })
        }
        </>
      )
    }
    else {
      return allowedDomains[0]
    }
  }

  function handlePublishDialogOpenDialog() {
    setPublishAlert(null)
    setPublishDialogState("START")
    setAlert(null)
  }

  function handlePublishDialogCloseClick() {
    setPublishAlert(null)
    setPublishDialogState("START")
    setAlert(null)
  }

  function getTargetLocationsAsString() {
    let l = []
    if (targetLocations) {
      l = targetLocations.map(t => t.label)
    }
    return (l.join(", "))
  }

  function getClickUrl() {
    return broadcastStatus === "DRAFT" ? `${"SOLV" === pageType ? mkHref({suffix: `/broadcasts/${broadcastId}/page`, tenant: tenant}) : pageUrl}` : `${"SOLV" === pageType ? `${env.DASSETS_ENDPOINT}/pages/${publicId}` : pageUrl}`
  }

  function FormControls() {

    const ctls = []

    if (tenant) {

      if (tenant.accessingAs("ADMIN/*/SYSTEM") && broadcastStatus === "FAILED_IMPORT") {
        ctls.push(
          <button key="key_btn_save" className="btn btn-primary" onClick={handleRepublishClick}>
            <i className="fas fa-paper-plane mr-2"></i>
            {intl.msg("republish")}
          </button>
        )
      }

      if (tenant.accessingAs(["BASIC/MEMBER/*", "ADMIN/MANAGER/*"])) {
        if (isAdding()) {
          ctls.push(
            <button key="key_btn_publish" className="btn btn-primary" onClick={handleSaveClick("publish")}>
              <i className="fas fa-paper-plane" style={{fontSize: "10pt"}}></i>
              {intl.msg("publish")}
            </button>
          )
          ctls.push(
            <button key="key_btn_save" className="btn btn-secondary" onClick={handleSaveClick("")}>
              <i className="fas fa-check"></i>
              {intl.msg("save")}
            </button>
          )
          ctls.push(
            <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
              <i className="fas fa-times"></i>
              {intl.msg("cancel")}
            </button>
          )
        }
        else {
          if (isEditing()) {
            ctls.push(
              <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick("")}>
                <i className="fas fa-check" style={{fontSize: "10pt"}}></i>
                {intl.msg("save")}
              </button>
            )
            ctls.push(
              <button key="key_btn_reset" className="btn btn-secondary" onClick={handleReloadClick}>
                <i className="fas fa-times"></i>
                {intl.msg("cancel")}
              </button>
            )
          }
          else {
            if (canPublish()) {
              ctls.push(
                <button key="key_btn_publish" className="btn btn-primary" onClick={openDialogCurried("dlg_publish")}>
                  <i className="fas fa-paper-plane" style={{fontSize: "10pt"}}></i>
                  {intl.msg("publish")}
                </button>
              )
            }
            if (canEdit()) {
              ctls.push(
                <button key="key_btn_edit" className="btn btn-secondary" onClick={handleEditClick}>
                  <i className="fas fa-edit"></i>
                  {intl.msg("edit")}
                </button>
              )
            }
            ctls.push(
              <MoreButton/>
            )
          }
        }
      }
      else if (tenant.accessingAs(["RESTRICTED/MEMBER/*", "BASIC/MANAGER/*"])) {
        ctls.push(
          <MoreButton/>
        )
      }
    }
    return (ctls)
  }

  return (
    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("broadcast")}
              {
                broadcastName &&
                  <span>
                    <span style={{paddingInline: "8px"}}>-</span>
                    {broadcastName}
                  </span>
              }

              {
                !isEditing() &&
                  <TagBox className="ml-1 mt-1">
                    <BroadcastStatusTagLabel broadcast={broadcast} tenant={tenant}/>
                  </TagBox>
              }
            </FormHeader.Toolbar.Title>

            <FormHeader.Toolbar.Controls>
              <FormControls/>
            </FormHeader.Toolbar.Controls>

          </FormHeader.Toolbar>

          <FormHeader.Alert alert={alert}/>

        </FormHeader>

        <FormBody>

          {
            !isEditing() && isPublished() &&
              <div className="row">
                <div className="col-12 justify-content-center">
                  <BroadcastSummaryWidgets broadcastPerformanceStats={broadcast?.stats}/>
                </div>
                {
                  isDelivering() &&
                    <div className="col-12 justify-content-center talign-center">
                      <small title={intl.dateTime(broadcastStatsUpdatedOn)} style={{color: "var(--form-control-label-muted-color)"}}>
                        {intl.msg("broadcast_stats_updated_on", {updatedOn: intl.fmFromNow(broadcastStatsUpdatedOn)})}
                      </small>
                    </div>
                }
              </div>
          }

          {
            session?.user?.isSystemUser() && isPublished() && adCampaignProps &&
              <FormGroups>
                <div className="row">

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_system")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div style={{width: "fit-content", maxWidth: "200px"}}>
                          <input id="inp_adSystem" type="text" className="form-control" maxLength={100} disabled={true} value={getAdSystemName(adSystem.value)}/>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_campaign")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div className="input-group-nowrap">
                          <div style={{width: "100%"}}>
                            <input id="inp_adCampaignName" type="text" className="form-control" disabled={true} value={adCampaignProps.campaignName}/>
                          </div>
                          <div key="key_div_adCampaignName" className="input-group-append ml-2">
                            <button key="key_btn_adCampaignId" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to test chatting with this number" disabled={false} onClick={(e) => window.open(`https://platform.amobee.com/webapp/#/insertion-order/${adCampaignProps.campaignId}?advertiserId=${adCampaignProps.advertiserId}`)}>
                              <i className="fas fa-external-link-alt"></i>
                            </button>
                          </div>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_start_time")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div style={{width: "100%"}}>
                          <input id="inp_adStartTime" type="text" className="form-control" disabled={true} value={adCampaignProps.startTime}/>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_end_time")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div style={{width: "100%"}}>
                          <input id="inp_adEndTime" type="text" className="form-control" disabled={true} value={adCampaignProps.endTime}/>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_cpm")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div className="d-flex align-items-center gap-2">
                          <div style={{width: "fit-content", maxWidth: "200px"}}>
                            <input id="inp_budget" type="text" className="form-control" disabled={true} value={intl.num(adCampaignProps.cpm)}/>
                          </div>
                          <div>USD</div>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <FormGroup.Label>
                        {intl.msg("ad_budget")}
                      </FormGroup.Label>
                      <FormGroup.Control>
                        <div className="d-flex align-items-center gap-2">
                        <div style={{width: "fit-content", maxWidth: "200px"}}>
                            <input id="inp_budget" type="text" className="form-control" disabled={true} value={intl.num(adCampaignProps.budget)}/>
                          </div>
                          <div>USD</div>
                        </div>
                      </FormGroup.Control>
                    </FormGroup>
                  </div>

                </div>

              </FormGroups>

          }

          <FormGroup disabled={!isEditing()}>
            <FormGroup.Label htmlFor="inp_broadcastName" text={intl.msg("broadcast_name")} description={intl.msg("broadcast_name_description")}/>
            <FormGroup.Control>
              <div style={{maxWidth: "800px"}}>
                <input ref={inpBroadcastNameRef} id="inp_broadcastName" autoFocus={true} name="broadcastName" type="text" className="form-control" value={broadcastName} maxLength="100" disabled={!isEditing()} onChange={handleBroadcastNameChange} autoComplete="no"/>
              </div>
              <InfoBlock>
                <InfoText disabled={!isEditing()}>{intl.msg("helptext_name_casing")}</InfoText>
                <InfoText validate="unique" disabled={!isEditing()}>{intl.msg("broadcast_name_helptext_unique")}</InfoText>
                <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Control>
          </FormGroup>

          {
            "IMAGE" === creativeType ? (
              <>
                <FormGroup disabled={!isEditing(creativeImageUrlLocked)}>
                  <FormGroup.Label htmlFor="inp_creativePhoto" text={intl.msg("broadcast_message_custom_image_message_type_photo")} description={intl.msg("broadcast_message_custom_image_message_type_photo_description")}/>
                  <FormGroup.Control>
                    <ImageDropBox
                      id="inp_creativePhoto"
                      image={creativeImageUrl}
                      imageClassName={"primary-photo-custom"}
                      width={300}
                      height={250}
                      onChange={handleCreativeImageUrlChange}
                      onClear={handleCreativePhotoClear}
                      disabled={!isEditing()}/>
                    <InfoBlock>
                      <InfoText disabled={!isEditing()}>{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_res")}</InfoText>
                      <InfoText validate="size" disabled={!isEditing()}>{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_size")}</InfoText>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}
                      </InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

              </>
            ) : (
              <>
                <FormGroup disabled={!isEditing(creativeHeaderLocked)}>
                  <FormGroup.Label htmlFor="creativeHeader" description={intl.msg("broadcast_message_header_description")}>
                    {intl.msg("broadcast_message_header")}
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <input id="inp_creativeHeader" name="broadcastName" type="text" className="form-control" value={creativeHeader} disabled={!canEdit(creativeHeaderLocked)} onChange={handleCreativeHeaderChange} autoComplete="no"/>
                    <InfoBlock>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                <FormGroup disabled={!isEditing(creativeTitleLocked)}>
                  <FormGroup.Label htmlFor="inp_creativeTitle" description={intl.msg("broadcast_message_title_description")}>
                    {intl.msg("broadcast_message_title")}
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <input id="inp_creativeTitle" type="text" className="form-control" value={creativeTitle} disabled={!canEdit(creativeTitleLocked)} autoComplete="no" onChange={handleCreativeTitleChange}/>
                    <InfoBlock>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                <FormGroup disabled={!isEditing(creativeImageUrlLocked)}>
                  <FormGroup.Label htmlFor="inp_creativePhoto" description={intl.msg("broadcast_message_solv_message_type_photo_description")}>
                    {intl.msg("broadcast_message_solv_message_type_photo")}
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <ImageDropBox
                      id="inp_creativePhoto"
                      image={creativeImageUrl}
                      imageClassName={"primary-photo-custom"}
                      width={120}
                      height={160}
                      onChange={handleCreativeImageUrlChange}
                      onClear={handleCreativePhotoClear}
                      disabled={!isEditing()}/>
                    <InfoBlock>
                      <InfoText validate="valid_size" disabled={!isEditing()}>{intl.msg("broadcast_message_solv_message_type_photo_helptext_valid")}</InfoText>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                {
                  creativeFormFields && creativeFormFields.map((field, index) =>
                    "READONLY" !== field.input &&
                    <FormGroup disabled={!isEditing()}>
                      <FormGroup.Label htmlFor={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`} description={field.description}>
                        {field.label}:
                      </FormGroup.Label>
                      <FormGroup.Control>
                        {
                          "READONLY" === field.input ? (
                            <>
                              {/* <label className="text">{field.value}</label> */}
                            </>
                          ) : (
                            <>
                              {
                                ["TEXT_LINE"].includes(field.type) ? (
                                  <input
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    type="text"
                                    className="form-control"
                                    value={creativeFormFields[index].value}
                                    disabled={!isEditing()}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}
                                    autoComplete="no"/>
                                ) : ["TEXT_BLOCK", "STATIC"].includes(field.type) ? (
                                  <textarea
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    className="form-control"
                                    rows="4"
                                    autoComplete="no"
                                    disabled={!isEditing()}
                                    value={creativeFormFields[index].value}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}
                                  />
                                ) : ["NUM_INT"].includes(field.type) ? (
                                  <NumericInput
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    className="form-control"
                                    style={{wrap: {width: "200px"}}}
                                    min={0}
                                    precision={0}
                                    disabled={!isEditing()}
                                    value={creativeFormFields[index].value}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}/>
                                ) : ["NUM_REAL"].includes(field.type) ? (
                                  <NumericInput
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    className="form-control"
                                    style={{wrap: {width: "200px"}}}
                                    min={0}
                                    precision={2}
                                    disabled={!isEditing()}
                                    value={creativeFormFields[index].value}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}/>
                                ) : ["DATE"].includes(field.type) ? (
                                  <DatePicker
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    selected={parseDate(creativeFormFields[index].value, tenant.region.regionId)}
                                    dateFormat={getDateFormat(tenant.region.regionId, "date-picker")}
                                    disabled={!isEditing()}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}/>
                                ) : ["SELECT"].includes(field.type) && field.opts && field.opts.selectList ? (
                                  <select
                                    key={['creativeFormField-', index].join('_')}
                                    id={`inp_creativeForm_${field.label.replace(/[\W_]+/g, '')}`}
                                    value={field.value}
                                    onChange={e => handleCreativeFormFieldChange(e, index)}
                                    className="form-control">
                                    {
                                      field.opts.selectList.split(",").map(o => <option
                                        value={o}>{o}</option>)
                                    }
                                  </select>
                                ) : (
                                  <>
                                  </>
                                )
                              }
                              {
                                (field.desc || "REQUIRED" === field.input) &&
                                <InfoBlock>
                                  {
                                    field.desc &&
                                    <InfoText>
                                      {field.desc}
                                    </InfoText>
                                  }
                                  {
                                    field.type && "TEXT_BLOCK" === field.type &&
                                    <InfoText>
                                      {intl.msg("helptext_you_can_use_markdown", {b: chunks => <strong>{chunks}</strong>})}
                                    </InfoText>
                                  }
                                  {
                                    "REQUIRED" === field.input &&
                                    <InfoText validate="required" disabled={!isEditing()}>
                                      {intl.msg("helptext_required")}
                                    </InfoText>
                                  }
                                </InfoBlock>
                              }
                            </>
                          )
                        }
                      </FormGroup.Control>
                    </FormGroup>
                  )
                }

                <FormGroup disabled={!isEditing(creativeFooterLocked)}>
                  <FormGroup.Label htmlFor="inp-creativeFooter" description={intl.msg("broadcast_message_footer_description")}>
                    {intl.msg("broadcast_message_footer")}:
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <input id="inp_creativeFooter" type="text" className="form-control" value={creativeFooter} disabled={!canEdit(creativeFooterLocked)} onChange={handleCreativeFooterChange} autoComplete="no"/>
                    <InfoBlock>
                      <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>
              </>
            )
          }

          <FormGroup disabled={!isEditing(pageUrlLocked)}>

          {
            !pageTypeLocked &&
              // <FormGroup disabled={!isEditing(pageUrlLocked)}>
              <>
                <FormGroup.Label htmlFor="inp_clickAction" text={intl.msg("broadcast_click_action")} description={intl.msg("broadcast_click_action_description")}/>
                <FormGroup.Control>
                  <div className="d-flex flex-row gap-2 w-100">
                    <div style={{minWidth: "500px", width: "max-content"}}>
                      <Select
                        id="inp_clickAction"
                        className="react-select react-select__absolute"
                        classNamePrefix="react-select"
                        value={clickAction}
                        options={getClickActionOpts(tenant, intl)}
                        onChange={handleClickActionChange}
                        isDisabled={!isEditing()}/>
                    </div>
                    {
                      "HOME" === pageType &&
                        <div key="key_div_homeUrl">
                          <button key="key_btn_homeUrl" className="btn btn-secondary" style={{
                            borderTop: "1px solid #555",
                            borderRight: "1px solid #555",
                            borderBottom: "1px solid #555",
                            width: "41px"
                          }} type="button" title="Click to view this page"
                                  onClick={(e) => window.open(tenant.homePage)}>
                            <i className="fas fa-external-link-alt"></i>
                          </button>
                        </div>
                    }
                  </div>
                  <InfoBlock>
                    <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                  </InfoBlock>
                </FormGroup.Control>
              {/*</FormGroup>*/}
            </>
          }

          {
            "EXTERNAL" === pageType ? (
              <div className="mt-2">
                {/*<FormGroup disabled={!isEditing(pageUrlLocked)}>*/}
                  <FormGroup.Label htmlFor="inp_pageUrl" description={intl.msg("broadcast_page_solv_page_page_type_page_url_description")}>
                    {intl.msg("broadcast_page_solv_page_page_type_page_url")}
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <div className="d-flex gap-2">
                      <input id="inp_pageUrl" name="pageUrl" type="text" className="form-control" value={pageUrl} disabled={!isEditing()} onChange={handleExtPageUrlChange} onBlur={handleExternalPageUrlBlur} onKeyDown={handleExternalPageUrlKeyDown} autoComplete="no"/><div key="key_div_externalUrl">
                      <button key="key_btn_externalUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to view this page" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}>
                        <i className="fas fa-external-link-alt"></i>
                      </button>
                    </div>
                    </div>
                    <InfoBlock>
                      <InfoText validate="valid" disabled={!isEditing()}>
                        {intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_valid")}
                      </InfoText>
                      {
                        (tenant.allowedDomains && tenant.allowedDomains.length > 0) &&
                        <InfoText validate="whitelist" disabled={!isEditing()}>
                          {intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_match", {allowedDomains: mkDomainListText(tenant.allowedDomains)})}
                        </InfoText>
                      }
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                {/*</FormGroup>*/}
              </div>
            ) : "WHATSAPP" === pageType ? (
              <div className="mt-2">
                {/*<FormGroup disabled={!isEditing(pageUrlLocked)}>*/}
                <FormGroup.Label htmlFor="inp_whatsappPhoneNo" description={intl.msg("broadcast_whatsapp_phone_no_description")}>
                  {intl.msg("broadcast_whatsapp_phone_no")}
                </FormGroup.Label>
                <FormGroup.Control>
                  <div className="input-group-nowrap">
                    <div style={{width: "200px"}}>
                      <input id="inp_whatsappPhoneNo" type="text" className="form-control" maxLength={15} disabled={!isEditing()} value={whatsAppPhoneNo} onChange={handleWhatsAppPhoneNoChange}/>
                    </div>
                    <div key="key_div_whatsAppUrl" className="input-group-append ml-2">
                      <button key="key_btn_whatsAppUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to test chatting with this number" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}>
                        <i className="fas fa-external-link-alt"></i>
                      </button>
                    </div>
                  </div>
                  <InfoBlock>
                    <InfoText validate="valid" disabled={!isEditing()}>
                      {intl.msg("broadcast_whatsapp_phone_no_helptext_valid")}
                    </InfoText>
                    <InfoText validate="required" disabled={!isEditing()}>
                      {intl.msg("helptext_required")}
                    </InfoText>
                  </InfoBlock>
                </FormGroup.Control>
                {/*</FormGroup>*/}
              </div>
            ) : (
              <></>
            )
          }

          {
            "SOLV" === pageType &&
            <>

              <div className="row">

                <div className="col">

                  <FormGroup disabled={!isEditing(pageHeaderLocked)}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_pageHeader">{intl.msg("broadcast_page_header")}:</label>
                    <div className="col-sm-10">
                      <input id="inp_pageHeader" type="text" className="form-control" value={pageHeader} disabled={!canEdit(pageHeaderLocked)} onChange={handlePageHeaderChange} autoComplete="no"/>
                      <InfoBlock>
                        <InfoText>{intl.msg("broadcast_page_header_helptext_1")}</InfoText>
                        <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </div>
                  </FormGroup>

                  <FormGroup disabled={!isEditing(pageTitleLocked)}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_pageTitle">{intl.msg("broadcast_page_title")}:</label>
                    <div className="col-sm-10">
                      <input id="inp_pageTitle" type="text" className="form-control" value={pageTitle} onChange={handlePageTitleChange} autoComplete="no" disabled={!canEdit(pageTitleLocked)}/>
                      <InfoBlock>
                        <InfoText>{intl.msg("broadcast_page_title_helptext_1")}</InfoText>
                        <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </div>
                  </FormGroup>

                  <FormGroup disabled={!isEditing(pageImageUrlLocked)}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_pagePhoto">{intl.msg("broadcast_page_photo")}:</label>
                    <div className="col-sm-10">
                      <ImageDropBox
                        id="inp_pagePhoto"
                        image={pageImageUrl}
                        imageClassName={"secondary-photo"}
                        width={300}
                        height={400}
                        onChange={handleSolvPageImageChange}
                        onClear={handlePageImageClear}
                        disabled={!isEditing()}/>
                      <InfoBlock>
                        <InfoText>{intl.msg("broadcast_page_photo_helptext_1")}</InfoText>
                        <InfoText disabled={!touched}>{intl.msg("broadcast_page_photo_helptext_2")}</InfoText>
                        <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </div>
                  </FormGroup>

                  {
                    pageFormFields && pageFormFields.map((field, index) =>
                      "READONLY" !== field.input &&
                      <FormGroup disabled={!isEditing()}>
                        <label className="col-sm-2 col-form-label" htmlFor={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}>{field.label}:</label>
                        <div className="col-sm-10">
                          {
                            "READONLY" === field.input ? (
                              <>
                                {/* <label className="text">{field.value}</label> */}
                              </>
                            ) : (
                              <>
                                {
                                  ["TEXT_LINE"].includes(field.type) ? (
                                    <input
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      type="text"
                                      className="form-control"
                                      value={pageFormFields[index].value}
                                      disabled={!isEditing()}
                                      onChange={e => handlePageFormFieldChange(e, index)}
                                      autoComplete="no"/>
                                  ) : ["TEXT_BLOCK", "STATIC"].includes(field.type) ? (
                                    <textarea
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      className="form-control"
                                      rows="4"
                                      autoComplete="no"
                                      disabled={!isEditing()}
                                      value={pageFormFields[index].value}
                                      onChange={e => handlePageFormFieldChange(e, index)}
                                    />
                                  ) : ["NUM_INT"].includes(field.type) ? (
                                    <NumericInput
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      className="form-control"
                                      style={{wrap: {width: "200px"}}}
                                      min={0}
                                      precision={0}
                                      disabled={!isEditing()}
                                      value={pageFormFields[index].value}
                                      onChange={e => handlePageFormFieldChange(e, index)}/>
                                  ) : ["NUM_REAL"].includes(field.type) ? (
                                    <NumericInput
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      className="form-control"
                                      style={{wrap: {width: "200px"}}}
                                      min={0}
                                      precision={2}
                                      disabled={!isEditing()}
                                      value={pageFormFields[index].value}
                                      onChange={e => handlePageFormFieldChange(e, index)}/>
                                  ) : ["DATE"].includes(field.type) ? (
                                    <DatePicker
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      selected={parseDate(pageFormFields[index].value, tenant.region.regionId)}
                                      dateFormat={getDateFormat(tenant.region.regionId, "date-picker")}
                                      disabled={!isEditing()}
                                      onChange={e => handlePageFormFieldChange(e, index)}/>
                                  ) : ["SELECT"].includes(field.type) && field.opts && field.opts.selectList ? (
                                    <select
                                      key={['pageFormField-', index].join('_')}
                                      id={`inp_pageForm_${field.label.replace(/[\W_]+/g, '')}`}
                                      value={field.value}
                                      onChange={e => handlePageFormFieldChange(e, index)}
                                      className="form-control">
                                      {
                                        field.opts.selectList.split(",").map(o => <option
                                          value={o}>{o}</option>)
                                      }
                                    </select>
                                  ) : (
                                    <>
                                    </>
                                  )
                                }
                                {
                                  (field.desc || "REQUIRED" === field.input) &&
                                  <InfoBlock>
                                    {
                                      field.desc &&
                                      <InfoText>{field.desc}</InfoText>
                                    }
                                    {
                                      field.type && "TEXT_BLOCK" === field.type &&
                                      <InfoText>{intl.msg("helptext_you_can_use_markdown", {
                                        b: chunks => <strong>{chunks}</strong>
                                      })}</InfoText>
                                    }
                                    {
                                      "REQUIRED" === field.input &&
                                      <InfoText validate="required"
                                                disable={!canEdit()}>{intl.msg("helptext_required")}</InfoText>
                                    }
                                  </InfoBlock>
                                }
                              </>
                            )
                          }
                        </div>
                      </FormGroup>
                    )
                  }

                  <FormGroup disabled={!isEditing()}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_feedbackEnabled">{intl.msg("broadcast_response")}:</label>
                    <div className="col-sm-10">
                      <ToggleButton
                        id="inp_feedbackEnabled"
                        checked={feedbackEnabled}
                        readonly={!canEdit()}
                        onChange={(v) => {
                          setFeedbackEnabled(v);
                          setTouched(true)
                        }}
                        onLabel={intl.msg("yes")}
                        offLabel={intl.msg("no")}/>
                      <InfoBlock>
                        <InfoText>{intl.msg("broadcast_response_helptext_1")}</InfoText>
                      </InfoBlock>
                    </div>
                  </FormGroup>

                </div>

                <div className="col-sm-5" style={{backgroundColor: "var(--form-background-color"}}>

                  {
                    <div style={{position: "sticky", "top": "240px"}}>
                      <div className="live-preview-section-divider">
                        {
                          "SOLV" === pageType ? (
                            <>
                              {intl.msg("broadcast_page_preview_section")}
                              {
                                !touched &&
                                <a className="icon-link pl-2" href={getClickUrl()} title={intl.msg("broadcast_page_preview_tooltip")} target="_blank"><i className="fas fa-external-link-alt"></i></a>
                              }
                            </>
                          ) : (
                            "LOADING" === extPageLoading ? (
                              <>
                                {intl.msg("broadcast_page_preview_section")}
                                <div className="live-preview-section-divider-spinner"></div>
                              </>
                            ) : "LOADED" === extPageLoading ? (
                              <>
                                {intl.msg("broadcast_page_preview_section")}
                                <a className="icon-link pl-2" href={getClickUrl()} title={intl.msg("broadcast_page_preview_tooltip")} target="_blank"><i className="fas fa-external-link-alt"></i></a>
                              </>
                            ) : (
                              <></>
                            )
                          )
                        }
                      </div>
                      <div className={`${pageType ? pageType.toLowerCase() : "solv"}-page-preview-container`}>
                        <div ref={pagePreviewRef}>
                          {
                            (("SOLV" === pageType) || (["LOADING", "LOADED"].includes(extPageLoading))) &&
                            <PagePreview
                              pageType={pageType}
                              pageUrl={pageUrl}
                              publishStatus={broadcastStatus}
                              logoUrl={tenant.logoUrl}
                              tenantName={tenant.tenantName}
                              tenantDisplayName={tenant.displayName}
                              homePage={tenant.homePage}
                              contactInfo={tenant.contactInfo}
                              pageTitle={pageTitle}
                              pageHeader={pageHeader}
                              pageContent={pageContent}
                              pageFormFields={pageFormFields}
                              pageStyle={pageStyle}
                              pageImageUrl={pageImageUrl}
                              feedbackEnabled={feedbackEnabled}
                              feedbackFormFields={feedbackFormFields}
                              feedbackThankyouText={feedbackThankyouText}
                              allowedDomains={tenant.allowedDomains}
                              preview={true}
                              width={pagePreviewWidth}
                              onLoad={handleExtPageUrlLoad}
                              onError={handleExtPageUrlError}
                            />
                          }
                        </div>
                      </div>

                      {
                        (("SOLV" === pageType && !touched) || ("LOADED" == extPageLoading)) &&
                        <div style={{marginTop: "10px"}}>
                          <small>
                            <span><i className="fas fa-info-circle"></i></span>&nbsp;
                            {
                              "EXTERNAL" === pageType ? (
                                <>
                                  {intl.msg("broadcast_page_preview_ext_page_helptext_1", {a: chunks => <a className="text-link" href={getClickUrl()} target="_blank">{chunks}</a>})}
                                </>
                              ) : (
                                <>
                                  {intl.msg("broadcast_page_preview_solv_page_helptext_1", {a: chunks => <a className="text-link" href={getClickUrl()} target="_blank">{chunks}</a>})}
                                </>
                              )

                            }
                          </small>
                        </div>
                      }

                    </div>
                  }

                </div>

              </div>

            </>

          }

          </FormGroup>

          <FormGroups>

            <FormGroup disabled={!isEditing()}>
              <FormGroup.Label htmlFor="inp_targetLocations" description={intl.msg("broadcast_target_locations_description")}>
                {intl.msg("broadcast_target_locations")}
              </FormGroup.Label>
              <FormGroup.Control>
                <AsyncPaginate
                  id="inp_targetLocations"
                  className="react-select"
                  classNamePrefix="react-select"
                  cacheOptions
                  isMulti
                  isClearable
                  value={targetLocations}
                  debounceTimeout={800}
                  defaultOptions={true}
                  loadOptions={loadTargetLocations}
                  reduceOptions={reduceGroupedOptions}
                  onChange={handleTargetLocationsChange}
                  isDisabled={!isEditing()}
                  style={{paddingBottom: "4px"}}
                />
                <InfoBlock>
                  <InfoText>
                    {intl.msg("broadcast_target_locations_helptext_estimations", {ttlPops: intl.int(popEstimations.ttlPop)})}
                  </InfoText>
                  <InfoText validate="range" disabled={!isEditing()}>
                    {intl.msg("broadcast_target_locations_helptext_range", {maxValue: MAX_LOCATION_COUNT})}
                  </InfoText>
                  <InfoText validate="required" disabled={!isEditing()}>
                    {intl.msg("helptext_required")}
                  </InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            {
              (targetLocations && targetLocations.length > 0) &&
              <>
                <FormGroup disabled={!isEditing()}>
                  <FormGroup.Label htmlFor="inp_targetBudget" text={intl.msg("broadcast_target_budget")} description={intl.msg("broadcast_target_budget_description")}/>
                  <FormGroup.Control>
                    <div style={{display: "flex", alignItems: "center", width: "120px"}}>
                      <NumericInput id="inp_targetBudget" className="form-control" style={false} min={10} precision={2} disabled={!isEditing()} value={targetBudget} onChange={handleTargetBudgetChange}/>
                      <div className="ml-2">{tenant.region.currencyCode ? tenant.region.currencyCode : "USD"}</div>
                    </div>
                    <InfoBlock>
                      <InfoText disabled={!targetLocations || targetLocations.length === 0}>
                        {intl.msg("broadcast_target_budget_helptext_estimations", {ttlImpressions: intl.int(popEstimations.ttlImpressions), pctReach: intl.num(popEstimations.pctReach), budgetToReach100Pct: intl.num(popEstimations.budgetToReach100Pct), currencyCode: tenant.region.currencyCode, isPublished: !isPublished()})}
                      </InfoText>
                      {/*<InfoText disabled={isPublished()}>*/}
                      {/*  {intl.msg("broadcast_target_budget_helptext_estimated_reach", {ttlImpressions: intl.int(popEstimations.ttlImpressions), pctReach: intl.num(popEstimations.pctReach), budgetToReach100Pct: intl.num(popEstimations.budgetToReach100Pct), currencyCode: tenant.region.currencyCode})}*/}
                      {/*</InfoText>*/}
                      <InfoText validate="min-value" disabled={!isEditing()}>
                        {intl.msg("broadcast_target_budget_helptext_min_value", {minValue: "1.00", currency: tenant.region.currencyCode})}
                      </InfoText>
                      <InfoText validate="required" disabled={!isEditing()}>
                        {intl.msg("helptext_required")}
                      </InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                <FormGroup disabled={!isEditing()}>
                  <FormGroup.Label htmlFor="inp_targetEndTime" description={intl.msg("broadcast_target_duration_description")}>
                    {intl.msg("broadcast_target_duration")}
                  </FormGroup.Label>
                  <FormGroup.Control>
                    <div style={{width: "200px"}}>
                      <Select
                        id="inp_end_time"
                        className="react-select"
                        classNamePrefix="react-select"
                        value={targetEndTime}
                        options={broadcastTimeOpts}
                        onChange={handleTargetEndTimeChange}
                        isDisabled={!isEditing()}
                      />
                    </div>
                    <InfoBlock>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>
              </>
            }

          </FormGroups>

        </FormBody>

      </MainContainer>

      {
        tenant &&
          <>
            <ImageDialog
              ref={creativeImagePickerDialogRef}
              id="dlg_creativeImagePicker"
              title="Message Photo"
              imageInfo={creativeImageInfo}
              setTargetImageData={setCreativeImageUrl}
              setTouched={setTouched}
            />

            <ImageDialog
              ref={pageImagePickerDialogRef}
              id="dlg_pageImagePicker"
              title="Page Photo"
              imageInfo={pageImageInfo}
              setTargetImageData={setPageImageUrl}
              setTouched={setTouched}
            />

            <BroadcastPublishDialog
              tenant={tenant}
              broadcastId={broadcastId}
              broadcastName={broadcastName}
              creativeType={creativeType}
              creativeHeader={creativeHeader ? creativeHeader : ""}
              creativeTitle={creativeTitle}
              creativeContent={creativeContent}
              creativeFormFields={creativeFormFields}
              creativeFooter={creativeFooter}
              creativeStyle={creativeStyle}
              logoUrl={tenant.logoUrl}
              creativeImageUrl={creativeImageUrl}
              clickAction={clickAction}
              pageUrl={pageUrl}
              whatsAppPhoneNo={whatsAppPhoneNo}
              targetBudget={targetBudget}
              targetLocations={targetLocations}
              targetEndTime={targetEndTime}
              popEstimations={popEstimations}
              borderColor={creativeBorderColor}
              allowedDomains={tenant.allowedDomains}
              usdMultiplier={usdMultiplier}
            />

            <BroadcastDetailsDialog
              tenant={tenant}
              broadcast={broadcast}
              backdrop="overlay"
            />

            <BroadcastCloneDialog
              tenant={tenant}
              broadcastId={broadcastId}
              broadcastName={broadcastName}
            />

            <BroadcastStopDialog
              tenant={tenant}
              broadcastId={broadcastId}
              broadcastName={broadcastName}
            />

            <BroadcastDeleteDialog
              tenant={tenant}
              broadcastId={broadcastId}
              broadcastName={broadcastName}
            />

            <BroadcastRefundDialog
              tenant={tenant}
              tenant={tenant}
              broadcastId={broadcastId}
              broadcastName={broadcastName}
              maxCredits={targetBudget}
            />

            <ExportToFileDialog
              key="broadcast"
              type="broadcast"
              tenant={tenant}
              fileName={broadcastName}
              fileExt={".zip"}
              onExport={handleExport}/>
          </>
      }
    </>
  )
}